import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { initTelegramApp } from './utils/telegramUtils';

const manifestUrl = 'https://private.timecommunity.xyz/tonconnect-manifest.json';

const AppWrapper = () => {
  useEffect(() => {
    const cleanup = initTelegramApp();
    return cleanup; // Вызываем функцию очистки при размонтировании компонента
  }, []);

  return (
    <React.StrictMode>
      <TonConnectUIProvider manifestUrl={manifestUrl}>
        <App />
      </TonConnectUIProvider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<AppWrapper />);