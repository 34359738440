import React, { useState, useRef, useCallback } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

interface NFTCarouselProps {
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

const NFTCarousel: React.FC<NFTCarouselProps> = ({ isCollapsed, toggleCollapse }) => {
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const carouselRef = useRef<HTMLDivElement>(null);
  const nftImages = Array.from({ length: 42 }, (_, i) => `/nft/nft${i + 1}.png`);

  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    setStartX(e.touches[0].pageX - (carouselRef.current?.offsetLeft || 0));
    setScrollLeft(carouselRef.current?.scrollLeft || 0);
  }, []);

  const handleTouchMove = useCallback((e: React.TouchEvent) => {
    if (!carouselRef.current) return;
    const x = e.touches[0].pageX - (carouselRef.current.offsetLeft || 0);
    const walk = (x - startX);
    carouselRef.current.scrollLeft = scrollLeft - walk;
  }, [startX, scrollLeft]);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    setIsMouseDown(true);
    setStartX(e.pageX - (carouselRef.current?.offsetLeft || 0));
    setScrollLeft(carouselRef.current?.scrollLeft || 0);
  }, []);

  const handleMouseMove = useCallback((e: React.MouseEvent) => {
    if (!isMouseDown || !carouselRef.current) return;
    e.preventDefault();
    const x = e.pageX - (carouselRef.current.offsetLeft || 0);
    const walk = (x - startX);
    carouselRef.current.scrollLeft = scrollLeft - walk;
  }, [isMouseDown, startX, scrollLeft]);

  const handleMouseUp = useCallback(() => {
    setIsMouseDown(false);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsMouseDown(false);
  }, []);

  const handleContextMenu = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
  }, []);

  return (
    <div className={`nft-carousel ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="nft-carousel-header">
        <h3 className="nft-title">NFT</h3>
        <button onClick={toggleCollapse} className="collapse-button">
          {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
        </button>
      </div>
      <div className={`nft-content ${isCollapsed ? 'collapsed' : ''}`}>
        <div 
          className="nft-images"
          ref={carouselRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          onContextMenu={handleContextMenu}
        >
          {nftImages.map((src, index) => (
            <img 
              key={index} 
              src={src} 
              alt={`NFT ${index + 1}`} 
              className="nft-image" 
              draggable="false"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default NFTCarousel;
