import React, { useState, useEffect, useCallback } from 'react';
import { TonConnectButton, useTonConnectUI } from '@tonconnect/ui-react';
import axios from 'axios';
import { FaSync, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import NFTCarousel from './NFTCarousel';
import { debounce, throttle } from 'lodash';
import Confetti from 'react-confetti'; 

interface TokenBalance {
  symbol: string;
  icon: string;
  balance?: string;
  price?: number;
  change?: number;
  displaySymbol?: string;
}

const TOKENS: TokenBalance[] = [
  { symbol: 'TIME', icon: '/icons/time.png' },
  { symbol: 'RESPECT', icon: '/icons/respect.png' },
  { symbol: 'TON', icon: '/icons/ton.png' },
  { symbol: 'USD₮', displaySymbol: 'USDT', icon: '/icons/usdt.png' },
];

const formatNumber = (num: string) => {
  const parts = num.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};

interface CabinetProps {
  sessionData: { hasNFT: boolean | null };
  setSessionData: React.Dispatch<React.SetStateAction<{ hasNFT: boolean | null }>>;
}

const HEIGHT_THRESHOLD = 660;

const Cabinet: React.FC = () => {
  const [tonConnectUI] = useTonConnectUI();
  const [balances, setBalances] = useState<TokenBalance[]>(TOKENS);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNFT, setHasNFT] = useState<boolean | null>(null);
  const [windowHeight, setWindowHeight] = useState(typeof window !== 'undefined' ? window.innerHeight : 0);
  const [isLoadingBalances, setIsLoadingBalances] = useState(false);
  const [isWalletCollapsed, setIsWalletCollapsed] = useState(false);
  const [isNFTCollapsed, setIsNFTCollapsed] = useState(false);
  const [nftCheckStatus, setNftCheckStatus] = useState('');
  const [isNftButtonDisabled, setIsNftButtonDisabled] = useState(false);
  const [hasNFTSession, setHasNFTSession] = useState<boolean | null>(null);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false); 

  useEffect(() => {
    const updateButtonState = () => {
      setIsNftButtonDisabled(!tonConnectUI.account);
    };
    updateButtonState();
    const unsubscribe = tonConnectUI.onStatusChange(updateButtonState);
    return () => {
      unsubscribe();
    };
  }, [tonConnectUI]);

  const updateUserWallet = useCallback(async (walletAddress: string) => {
    if (!walletAddress) return;
    const tg = window.Telegram?.WebApp;
    const telegramId = tg?.initDataUnsafe?.user?.id?.toString();
    const username = tg?.initDataUnsafe?.user?.username;
    if (!telegramId) {
      console.error('Не удалось получить Telegram ID пользователя');
      // Возможно, уведомление пользователя или повторная попытка получения данных
      return;
    }
    console.log('Updating wallet:', { walletAddress, telegramId, username });
    try {
      await tg.sendData(JSON.stringify({ 
        action: 'updateWallet', 
        walletAddress, 
        telegramId, 
        username 
      }));
      console.log('Данные отправлены в Telegram WebApp');
    } catch (error) {
      console.error('Ошибка при отправке данных через Telegram WebApp:', error);
    }
  }, []);

  const isSmallScreen = windowHeight <= HEIGHT_THRESHOLD;

  useEffect(() => {
    const handleResize = debounce(() => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      setWindowHeight(newHeight);
      if (newHeight <= HEIGHT_THRESHOLD) {
        setIsWalletCollapsed(true);
        setIsNFTCollapsed(false);
      } else {
        setIsWalletCollapsed(false);
        setIsNFTCollapsed(false);
      }
    }, 200);
    
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const initialWalletCollapsedState = window.innerHeight <= HEIGHT_THRESHOLD;
    setIsWalletCollapsed(initialWalletCollapsedState);
    setIsNFTCollapsed(true); // NFT всегда свернут при запуске
  }, []);

  const throttledFetchBalances = useCallback(
    throttle(async () => {
      if (!tonConnectUI.account) return;
      setIsLoadingBalances(true);
      try {
        const [tonBalance, jettonBalances] = await Promise.all([
          axios.get(`https://tonapi.io/v2/blockchain/accounts/${tonConnectUI.account.address}`, {
            headers: { 'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}` },
          }),
          axios.get(`https://tonapi.io/v2/accounts/${tonConnectUI.account.address}/jettons`, {
            headers: { 'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}` },
          })
        ]);

        const updatedBalances = balances.map(token => {
          if (token.symbol === 'TON') {
            const balance = (parseFloat(tonBalance.data.balance) / 1e9).toFixed(2);
            return { ...token, balance };
          } else {
            const jetton = jettonBalances.data.balances.find((j: any) => 
              j.jetton.symbol === token.symbol || j.jetton.symbol === token.displaySymbol
            );
            const balance = jetton ? (parseFloat(jetton.balance) / Math.pow(10, jetton.jetton.decimals)).toFixed(2) : '0';
            return { ...token, balance };
          }
        });

        setBalances(updatedBalances);
        setTimeout(() => setIsLoadingBalances(false), 300);
      } catch (error) {
        console.error('Ошибка при получении балансов:', error);
        setIsLoadingBalances(false);
      }
    }, 5000, { leading: true, trailing: false }),
    [tonConnectUI.account]
  );

  useEffect(() => {
    if (tonConnectUI.account) {
      throttledFetchBalances();
    }
  }, [tonConnectUI.account, throttledFetchBalances]);

  useEffect(() => {
    console.log('tonConnectUI.account изменился:', tonConnectUI.account);
    if (tonConnectUI.account) {
      throttledFetchBalances();
      updateUserWallet(tonConnectUI.account.address);
      setIsNftButtonDisabled(false);
      setIsWalletConnected(true); // Устанавливаем состояние подключения
    } else {
      setIsNftButtonDisabled(true);
      setIsWalletConnected(false); // Сбрасываем состояние подключения
    }
    setHasNFT(null);
    setHasNFTSession(null);
    setNftCheckStatus('');
  }, [tonConnectUI.account, throttledFetchBalances, updateUserWallet]);
  

  // Функция проверки NFT
  const checkNFT = useCallback(async () => {
    if (!tonConnectUI.account) {
      console.log('Кошелек не подключен');
      setIsNftButtonDisabled(true);
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get(`https://tonapi.io/v2/accounts/${tonConnectUI.account.address}/nfts`, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`,
        },
      });
      
      const userNFTs = response.data.nft_items.map((nft: any) => nft.address);
      const nftListModule = await import('../nftList.json');
      const nftList = nftListModule.default;
      const hasCollectionNFT = userNFTs.some((nftAddress: string) => nftList.includes(nftAddress));
      
      setHasNFT(hasCollectionNFT);
      setHasNFTSession(hasCollectionNFT);
      setNftCheckStatus(hasCollectionNFT ? 'У тебя есть NFT! Отправил ссылку' : 'У тебя нет NFT. Купить на Getgems');
      setIsNftButtonDisabled(hasCollectionNFT);

      const tg = window.Telegram?.WebApp;
      const telegramId = tg?.initDataUnsafe?.user?.id?.toString();
      const username = tg?.initDataUnsafe?.user?.username;
      
      if (!telegramId) {
        throw new Error('Не удалось получить Telegram ID пользователя');
      }
      
      // Отправляем данные на API-сервер вместо бота
    await axios.post(`${process.env.REACT_APP_API_URL}/api/check-nft`, {
      walletAddress: tonConnectUI.account.address,
      telegramId: telegramId,
      username: username,
      hasNFT: hasCollectionNFT
    });
    
    console.log('Запрос на проверку NFT отправлен на API-сервер');

// Если NFT проверка успешна, показать конфетти и закрыть приложение
if (hasCollectionNFT) {
  setShowConfetti(true); // Показываем конфетти
  setTimeout(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.close();
    } else {
      console.error('Telegram WebApp API недоступен');
    }
  }, 3000);
  
  // Скрываем конфетти через 3 секунды
  setTimeout(() => {
    setShowConfetti(false);
  }, 2500);
}

  } catch (error) {
    console.error('Ошибка при проверке NFT:', error);
    setHasNFT(false);
    setHasNFTSession(false);
    setNftCheckStatus('Ошибка при проверке NFT');
  } finally {
    setIsLoading(false);
  }
}, [tonConnectUI.account, setHasNFT, setHasNFTSession, setNftCheckStatus, setIsNftButtonDisabled]);

  useEffect(() => {
    console.log('isNftButtonDisabled изменился:', isNftButtonDisabled);
  }, [isNftButtonDisabled]);

  useEffect(() => {
    console.log('Button state:', {
      isLoading,
      isNftButtonDisabled,
      hasAccount: !!tonConnectUI.account
    });
  }, [isLoading, isNftButtonDisabled, tonConnectUI.account]);

  

  const toggleWalletCollapse = () => {
    if (isSmallScreen) {
      setIsWalletCollapsed(!isWalletCollapsed);
      if (isWalletCollapsed) {
        setIsNFTCollapsed(true);
      }
    } else {
      setIsWalletCollapsed(!isWalletCollapsed);
    }
  };

  const toggleNFTCollapse = () => {
    if (isSmallScreen) {
      setIsNFTCollapsed(!isNFTCollapsed);
      if (isNFTCollapsed) {
        setIsWalletCollapsed(true);
      }
    } else {
      setIsNFTCollapsed(!isNFTCollapsed);
    }
  };

  return (
    <div className="cabinet">
        {showConfetti && (
   <Confetti 
   width={window.innerWidth} 
   height={window.innerHeight} 
   recycle={false} 
   numberOfPieces={300} 
   gravity={0.4} 
   colors={['#FF5733', '#33FF57', '#3357FF', '#F3FF33', '#FF33F6']}
 />
)}
      <div className="cabinet-header">
      <div className={`connect-wallet-button ${isWalletConnected ? 'connected' : ''}`}>
          <TonConnectButton />
        </div>
      </div>
      <div className="cabinet-content">
        <div className={`wallet-section ${isWalletCollapsed ? 'collapsed' : ''}`}>
          <div className="wallet-header">
            <h2 className="wallet-title">Wallet</h2>
            <div className="cabinet-controls">
              <button 
                onClick={throttledFetchBalances} 
                disabled={isLoadingBalances} 
                className={`refresh-button ${isLoadingBalances ? 'loading' : ''}`}
              >
                <FaSync />
              </button>
              <button 
                onClick={toggleWalletCollapse} 
                className="collapse-button"
              >
                {isWalletCollapsed ? <FaChevronDown /> : <FaChevronUp />}
              </button>
            </div>
          </div>
          <div className={`wallet-content ${isWalletCollapsed ? 'collapsed' : ''}`}>
            <div className="token-list">
              {balances.map((token, index) => (
                <div 
                  key={index} 
                  className="token-item"                
                >
                  <div className="token-info">
                    {token.icon ? (
                      <img 
                        src={token.icon}
                        alt={token.displaySymbol || token.symbol} 
                        className="token-icon"
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                          e.currentTarget.src = '/icons/default.png';
                        }}
                      />
                    ) : (
                      <div className="token-icon-placeholder"></div>
                    )}
                    <div className="token-details">
                      <div className="token-symbol">{token.displaySymbol || token.symbol}</div>
                    </div>
                  </div>
                  <div className="token-balance-container">
                    <div className={`token-balance ${isLoadingBalances ? 'updating' : 'loaded'}`}>
                      {token.balance ? formatNumber(token.balance) : '—'}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <NFTCarousel isCollapsed={isNFTCollapsed} toggleCollapse={toggleNFTCollapse} />
      </div>

      <div className="nft-check">
        {hasNFTSession ? (
          <button 
            disabled={true}
            className="check-nft-button success"
          >
            У тебя есть NFT! Доступ получен
          </button>
        ) : hasNFT === false ? (
          <a 
            href="https://getgems.io/timecommunity" 
            target="_blank" 
            rel="noopener noreferrer"
            className="check-nft-button error pulse-animation"
          >
            У тебя нет NFT. Купить на Getgems
          </a>
        ) : (
          <button 
            onClick={checkNFT} 
            disabled={isLoading || isNftButtonDisabled || !tonConnectUI.account} 
            className={`check-nft-button ${isLoading ? 'loading' : ''} ${nftCheckStatus ? (hasNFT ? 'success' : 'error') : ''} ${(!isLoading && !isNftButtonDisabled && tonConnectUI.account) ? 'pulse-animation' : ''}`}
>
            {isLoading ? 'Проверка NFT...' : nftCheckStatus || 'Проверить NFT'}
          </button>
        )}
      </div>
    </div>
  );
};

export default Cabinet;
