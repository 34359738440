import React, { useState, useEffect } from 'react';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { FaGamepad, FaUser } from 'react-icons/fa';
import Cabinet from './components/Cabinet';
import Game from './components/Game';
import './App.css';

function App() {
  const [tonConnectUI] = useTonConnectUI();
  const [activeTab, setActiveTab] = useState<'cabinet' | 'game'>('cabinet');
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const checkWalletConnection = () => {
    };

    checkWalletConnection();
    const unsubscribe = tonConnectUI.onStatusChange(checkWalletConnection);

    return () => {
      unsubscribe();
    };
  }, [tonConnectUI]);

  const handleTabChange = (tab: 'cabinet' | 'game') => {
    setIsTransitioning(true);
    setTimeout(() => {
      setActiveTab(tab);
      setIsTransitioning(false);
    }, 300); // Длительность анимации
  };

  return (
    <div className="App">
      <header className={`App-header ${isTransitioning ? 'transitioning' : ''}`}>
        
      </header>
      <main className="App-main">
        <div className={`page cabinet-page ${activeTab === 'cabinet' ? 'visible' : 'hidden'} ${isTransitioning ? 'transitioning' : ''}`}>
          <Cabinet />
        </div>
        <div className={`page game-page ${activeTab === 'game' ? 'visible' : 'hidden'} ${isTransitioning ? 'transitioning' : ''}`}>
          <Game />
        </div>
      </main>
      <nav className="App-nav">
        <button 
          className={`nav-button ${activeTab === 'game' ? 'active' : ''}`} 
          onClick={() => handleTabChange('game')}
        >
          <FaGamepad />
          <span>Игра</span>
        </button>
        <button 
          className={`nav-button ${activeTab === 'cabinet' ? 'active' : ''}`} 
          onClick={() => handleTabChange('cabinet')}
        >
          <FaUser />
          <span>Кабинет</span>
        </button>
      </nav>
    </div>
  );
}

export default App;
