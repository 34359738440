import React from 'react';

const Game: React.FC = () => {
  return (
    <div className="game-container">
      <p className="game-text">
        Скоро...
      </p>
    </div>
  );
};

export default Game;
